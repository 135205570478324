import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import calendar from "dayjs/plugin/calendar";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import { TimestampSlot } from "@/types/pro-availability";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import constants from "@/utils/constants";

dayjs.extend(LocalizedFormat);
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.updateLocale("en", {
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] ddd [at] LT",
    nextWeek: "ddd [at] LT",
    sameElse: "L",
  },
});

export const parseMsgDayDelimiter = (
  date: string | Date,
  daysAgo = false,
): string => {
  if (!date) {
    return "-";
  }

  const value = dayjs(date);

  const today = dayjs().startOf("day");
  const isToday = value.isSame(today, "day");
  if (isToday) {
    return "Today";
  }

  const yesterday = dayjs().subtract(1, "day").startOf("day");
  const isYesterday = value.isSame(yesterday, "day");
  if (isYesterday) {
    return "Yesterday";
  }
  if (daysAgo) {
    return `${today.diff(value, "days")} days ago`;
  }

  return value.format("MM/DD/YYYY");
};

export const parseTimeSelectDate = (date: string | Date): string => {
  if (!date) {
    return "-";
  }
  const value = dayjs(date);

  const tomorrow = dayjs().add(1, "day").startOf("day");
  const isTomorrow = value.isSame(tomorrow, "day");
  if (isTomorrow) {
    return value.format("[Tomorrow,] MMMM DD");
  }

  return value.format("dddd, MMMM DD");
};

export const parseMessageDate = (date: string | Date): string => {
  if (!date) {
    return "-";
  }

  const value = dayjs(date);

  const today = dayjs().startOf("day");
  const isToday = value.isSame(today, "day");
  if (isToday) {
    return value.format("hh:mm A");
  }

  const yesterday = dayjs().subtract(1, "day").startOf("day");
  const isYesterday = value.isSame(yesterday, "day");
  if (isYesterday) {
    return value.format("[Yesterday,] hh:mm A");
  }

  return value.format("DD/MM/YYYY hh:mm A");
};

export const getRemainingTimeFeedback = (endDate: string) => {
  const end = dayjs(endDate);
  const startTime = dayjs();
  const duration = dayjs.duration(end.diff(startTime));
  const days = Math.floor(duration.asDays());

  if (days > 2) {
    return `${days} days left`;
  }

  if (days < 0) {
    return `${days} expired days`;
  }

  const seconds = Math.floor((duration.asSeconds() % 3600) % 60);
  const minutes = Math.floor(duration.asMinutes() % 60);
  const hours = Math.floor(duration.asHours());

  return `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const getRemainingTimeCall = (time: string, timezone: string) => {
  const end = dayjs(time).tz(timezone);
  const startTime = dayjs().tz(timezone);
  const duration = dayjs.duration(end.diff(startTime));
  const days = Math.floor(duration.asDays());

  if (days === 0 || days === 1) {
    return end.calendar(startTime) + ` ${printableTimezone(timezone)}`;
  }
  if (days > 1 || days < 0) {
    return `${end.format("MMM D, LT")} ${printableTimezone(timezone)}`;
  }

  return "";
};

export const printableTimezone = (tzCode: string) => {
  const printableTimezone = constants.timezones.find(
    (el) => tzCode === el.tzCode,
  );
  return printableTimezone?.name.split(",")[0] || timezone;
};

export const printableShortTimezone = (tzCode: string) => {
  const printableTimezone = constants.timezones.find(
    (el) => tzCode === el.tzCode,
  );
  return printableTimezone?.label.split(" ")[0] || timezone;
};

export const getRemainingMinutes = (time: string) => {
  const end = dayjs(time);
  const startTime = dayjs();
  const duration = dayjs.duration(end.diff(startTime));
  const hours = Math.floor(duration.asHours() % 60);
  const seconds = Math.floor((duration.asSeconds() % 3600) % 60);
  const minutes = Math.floor(duration.asMinutes() % 60);

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
};

export const getTextColor = (time?: string): string => {
  if (!time) return "text-accent-red";

  const timeDiff = dayjs(time).diff(dayjs());

  if (timeDiff > 0 && timeDiff <= 3600000) {
    return "text-accent-green";
  } else {
    return "text-grayscale-60";
  }
};

export const getRequestDisplayTime = (date: Date, timezone: string): string => {
  return (
    dayjs(date).tz(timezone).calendar(null, {
      sameDay: "[Today at] h:mm A",
      nextDay: "[Tomorrow at] h:mm A",
      nextWeek: "dddd [at] h:mm A",
      lastDay: "[Yesterday at] h:mm A",
      lastWeek: "[Last] dddd [at] h:mm A",
      sameElse: "DD/MM/YYYY [at] h:mm A",
    }) +
    " " +
    printableTimezone(timezone)
  );
};

export const getRequestDate = (date?: Date): string => {
  return dayjs(date).format("MMM D");
};

export const isDateToday = (date?: Date): boolean => {
  return dayjs(date).isSame(dayjs(), "day");
};

export const isBetween = (value: number, left: number, right: number) => {
  return value >= left && value <= right;
};

export const isIntersectingIntervals = (
  left: TimestampSlot,
  right: TimestampSlot,
) => {
  return (
    isBetween(left.start, right.start, right.end) ||
    isBetween(left.end, right.start, right.end) ||
    isBetween(right.start, left.start, left.end) ||
    isBetween(right.end, left.start, left.end)
  );
};
